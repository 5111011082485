@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@500&family=Bebas+Neue&family=Montserrat&family=Nanum+Myeongjo:wght@700&family=Raleway:wght@100&family=Sacramento&family=Staatliches&family=Tangerine:wght@700&family=Unica+One&family=Zen+Kurenaido&display=swap");

.about {
  padding-top: 210px;
  max-height: 100%;
  overflow: hidden;
}
.picture {
  border-radius: 1%;
  height: 450px;
  width: auto;
  padding-right: 40px;
}
.aboutdiv {
  margin-left: 120px;
  width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.abouttext {
  font-family: "Bebas Neue", cursive;
  text-decoration: none; /* no underline */
  color: black;
  font-size: 27px;
}
.hi {
  font-size: 60px;
}
@media only screen and (max-width: 1350px) {
  .about {
    padding-top: 175px;
  }
  .aboutdiv {
    margin-left: 60px;
    width: 900px;
  }
  .picture {
    height: 350px;
  }
  .abttextdiv {
    padding-top: 1vh;
  }
  .abouttext {
    font-size: 20px;
  }
  .hi {
    font-size: 40px;
  }
}
@media only screen and (max-width: 950px) {
  .aboutdiv {
    margin-left: 10vw;
    flex-direction: column;
    justify-content: center;
    width: 80vw;
    margin-bottom: 100px;
  }
  .picture {
    height: 60vw;
    padding: 0;
  }
  .abttextdiv {
    padding-top: 3vh;
    width: 65vw;
  }
  .abouttext {
    text-align: center;
  }
}
