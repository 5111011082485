.contactfinal {
  padding-top: 150px;
  position: absolute;
  left: 50%;
  background-color: white;
  height: 100vw-200px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: #f6f8ff;
  padding: 3rem;
}
.inputform {
  width: 40vw;
  border: none;
  background-color: #c1c7d8;
  padding: 1rem;
  margin: 15px;
  font-family: "Bebas Neue", cursive;
  text-decoration: none;
  color: black;


}
.contacttext {
  font-family: "Bebas Neue", cursive;
  color: black;
  font-size: 250%;
  text-decoration: none;
}
.but {
  width: 20vw;
  padding: 0.5rem;
}
.inputform:hover {
  background-color: rgb(32, 37, 42);
  color: white;
  transition: 0.5s;
}
.email {
  opacity: 0;
  transition: 0.5s;
}
.sent {
  opacity: 100;
  transition: 0.5s;
}
textarea {
  resize: none;
}
@media only screen and (max-height: 615px) {
  .contactfinal {
    height: 100vh;
    padding-top: 150px;
  }
  .contact {
    padding: 1rem;
  }
  .contacttext {
    font-size: 200%;
  }
}
@media only screen and (max-height: 600px) {
  .contactfinal {
    height: 100vh;
    padding-top: 150px;
  }
  .contact {
    padding: 1rem;
  }
  .contacttext {
    font-size: 200%;
  }
  .inputform {
    padding:.25rem;
    margin: 10px;
  }
}
@media only screen and (max-height: 500px) {
  .inputform {
    margin: 5px;
  }
}